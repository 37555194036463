import * as types from './actionTypes';

const initialState = {
  segments: [],
  loading: false,
  error: null,
};

const segmentler = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_SEGMENTS_REQUEST:
    case types.ADD_SEGMENT_REQUEST:
    case types.UPDATE_SEGMENT_REQUEST:
    case types.DELETE_SEGMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.LIST_SEGMENTS_SUCCESS:
      return {
        ...state,
        segments: action.payload,
        loading: false,
      };
    case types.ADD_SEGMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.UPDATE_SEGMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.DELETE_SEGMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.LIST_SEGMENTS_FAILURE:
    case types.ADD_SEGMENT_FAILURE:
    case types.UPDATE_SEGMENT_FAILURE:
    case types.DELETE_SEGMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export default segmentler;
