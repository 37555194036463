import { takeLatest, call, put } from "redux-saga/effects"
import * as types from "./actionTypes"
import * as api from "helpers/api_helper.js"
import { reverseSortById } from "../../helpers/utils"

function* listBolgelerSaga() {
  try {
    const bolgeler = yield call(api.listBolgeler)
    yield put({ type: types.LIST_BOLGELER_SUCCESS, payload: reverseSortById(bolgeler) })
  } catch (error) {
    yield put({ type: types.LIST_BOLGELER_FAILURE, payload: error.message })
  }
}

function* addBolgeSaga(action) {
  try {
    yield call(api.addBolge, action.payload)
    yield call(listBolgelerSaga) // Refresh segments after addition
  } catch (error) {
    yield put({ type: types.ADD_BOLGE_FAILURE, payload: error.message })
  }
}

function* updateBolgeSaga(action) {
  try {
    const { segmentData } = action.payload
    yield call(api.updateBolge, segmentData.id, segmentData)
    yield call(listBolgelerSaga) // Refresh segments after update
  } catch (error) {
    yield put({ type: types.UPDATE_BOLGE_FAILURE, payload: error.message })
  }
}

function* deleteBolgeSaga(action) {
  try {
    const id = action.payload
    yield call(api.deleteBolge, id)
    yield call(listBolgelerSaga) // Refresh segments after deletion
  } catch (error) {
    yield put({ type: types.DELETE_BOLGE_FAILURE, payload: error.message })
  }
}

export default function* bolgeSaga() {
  yield takeLatest(types.LIST_BOLGELER_REQUEST, listBolgelerSaga)
  yield takeLatest(types.ADD_BOLGE_REQUEST, addBolgeSaga)
  yield takeLatest(types.UPDATE_BOLGE_REQUEST, updateBolgeSaga)
  yield takeLatest(types.DELETE_BOLGE_REQUEST, deleteBolgeSaga)
}
