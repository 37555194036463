import turkeyFlag from "../assets/images/flags/turkey.png"

const languages = {
  tr: {
    label: "Türkçe",
    flag: turkeyFlag
  }
  /*,
  en: {
    label: "English",
    flag: ukFlag,
  },*/
}

export default languages
