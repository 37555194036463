export const LIST_BOLGELER_REQUEST = 'LIST_BOLGELER_REQUEST';
export const LIST_BOLGELER_SUCCESS = 'LIST_BOLGELER_SUCCESS';
export const LIST_BOLGELER_FAILURE = 'LIST_BOLGELER_FAILURE';

export const ADD_BOLGE_REQUEST = 'ADD_BOLGE_REQUEST';
export const ADD_BOLGE_SUCCESS = 'ADD_BOLGE_SUCCESS';
export const ADD_BOLGE_FAILURE = 'ADD_BOLGE_FAILURE';

export const UPDATE_BOLGE_REQUEST = 'UPDATE_BOLGE_REQUEST';
export const UPDATE_BOLGE_SUCCESS = 'UPDATE_BOLGE_SUCCESS';
export const UPDATE_BOLGE_FAILURE = 'UPDATE_BOLGE_FAILURE';

export const DELETE_BOLGE_REQUEST = 'DELETE_BOLGE_REQUEST';
export const DELETE_BOLGE_SUCCESS = 'DELETE_BOLGE_SUCCESS';
export const DELETE_BOLGE_FAILURE = 'DELETE_BOLGE_FAILURE';
