import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Profile from "./auth/profile/reducer";
import Segmentler from "./segmentler/reducer";
import Bolgeler from "./bolgeler/reducer";
import BmKullanicilar from "./bmKullanicilar/reducer";
import AsuKullanicilar from "./asuKullanicilar/reducer";
import Acenteler from "./acenteler/reducer";
import Policeler from "./policeTipleri/reducer";
import SatilanPoliceler from "./satilanPoliceler/reducer";
import AcentePolicePrimHedef from "./acentePolicePrimHedef/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Segmentler,
  Bolgeler,
  BmKullanicilar,
  AsuKullanicilar,
  Acenteler,
  Policeler,
  AcentePolicePrimHedef,
  SatilanPoliceler,
  Profile,
});

export default rootReducer;
