export const LIST_ASU_KULLANICILAR_REQUEST = 'LIST_ASU_KULLANICILAR_REQUEST';
export const LIST_ASU_KULLANICILAR_SUCCESS = 'LIST_ASU_KULLANICILAR_SUCCESS';
export const LIST_ASU_KULLANICILAR_FAILURE = 'LIST_ASU_KULLANICILAR_FAILURE';

export const ADD_ASU_KULLANICI_REQUEST = 'ADD_ASU_KULLANICI_REQUEST';
export const ADD_ASU_KULLANICI_SUCCESS = 'ADD_ASU_KULLANICI_SUCCESS';
export const ADD_ASU_KULLANICI_FAILURE = 'ADD_ASU_KULLANICI_FAILURE';

export const UPDATE_ASU_KULLANICI_REQUEST = 'UPDATE_ASU_KULLANICI_REQUEST';
export const UPDATE_ASU_KULLANICI_SUCCESS = 'UPDATE_ASU_KULLANICI_SUCCESS';
export const UPDATE_ASU_KULLANICI_FAILURE = 'UPDATE_ASU_KULLANICI_FAILURE';

export const DELETE_ASU_KULLANICI_REQUEST = 'DELETE_ASU_KULLANICI_REQUEST';
export const DELETE_ASU_KULLANICI_SUCCESS = 'DELETE_ASU_KULLANICI_SUCCESS';
export const DELETE_ASU_KULLANICI_FAILURE = 'DELETE_ASU_KULLANICI_FAILURE';
