import React from "react"
import ReactApexChart from "react-apexcharts"

const barchart =  ({ name, series, title }) => {
  const options = {
    title: {
      text: title,
      align: "center",
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: "Outfit",
        color: "#263238"
      }
    },
    series: [{
      name: [name],
      data: series.data,
    }],
    chart: {
      type: "bar",
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | "<img src=\"/static/icons/reset.png\" width=\"20\">",
          customIcons: []
        },
        autoSelected: "zoom"
      }
    },

    plotOptions: {
      bar: {
        horizontal: false,
        colors: {
          ranges: [{
            from: 0,
            to: 50,
            color: "#FF4560"
          }, {
            from: 51,
            to: 99,
            color: "#FEB019"
          }, {
            from: 100,
            to: 150,
            color: "#00E396"
          }]
        }
      }
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"]
      }
    },
    xaxis: {
      categories: [name]
    },
    yaxis: {
      labels: {
        formatter: function(val) {
          return val.toFixed(2) + "%"
        }
        //  rotate: -90
      }
    },
    grid: {
      borderColor: "#f1f1f1"
    },
    responsive: [{
      breakpoint: 1000,
      options: {
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        legend: {
          position: "bottom"
        }
      }
    }]
  }

  return (
    <React.Fragment>
    <ReactApexChart options={options} series={series} type="bar" height="350" />
    </React.Fragment>
  )
}

export default barchart
