export const selectStyles = {
  placeholder: (base, state) => ({
    ...base,
    color: "rgba(0,0,0,0.7)",
  }),
  input: (base, state) => ({
    ...base,
    color: "black",
  }),
  control: (base, state) => ({
    ...base,
    color: "black",

    placeholder: "white",
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
  }),
  menu: base => ({
    ...base,
    zIndex: 100,
    //minHeight:"200px",
    color: "black",
    backgroundColor: "white",
  }),
};