import * as types from './actionTypes';
export const listSegmentsRequest = () => ({
  type: types.LIST_SEGMENTS_REQUEST,
});

export const addSegmentRequest = (segmentData) => ({
  type: types.ADD_SEGMENT_REQUEST,
  payload: segmentData,
});

export const updateSegmentRequest = (segmentData) => ({
  type: types.UPDATE_SEGMENT_REQUEST,
  payload: { segmentData },
});

export const deleteSegmentRequest = (id) => ({
  type: types.DELETE_SEGMENT_REQUEST,
  payload: id,
});
export const getSegmentDetailRequest = (id) => ({
  type: types.GET_SEGMENT_DETAIL_REQUEST,
  payload: id,
});