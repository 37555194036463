import {all, fork} from "redux-saga/effects";

//public
import AuthSaga from "./auth/login/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import SegmentSaga from "./segmentler/saga"
import AcenteSaga from "./acenteler/saga"
import BolgeSaga from "./bolgeler/saga"
import BmKullanicilarSaga from "./bmKullanicilar/saga"
import AsuKullanicilarSaga from "./asuKullanicilar/saga"
import PolicelerSaga from "./policeTipleri/saga"
import AcentePolicePrimHedefSaga from "./acentePolicePrimHedef/saga"
import SatilanPolicelerSaga from "./satilanPoliceler/saga"

export default function* rootSaga() {
    yield all([
        //public
        fork(AuthSaga),
        fork(ProfileSaga),
        fork(LayoutSaga),
        fork(SegmentSaga),
        fork(BolgeSaga),
        fork(BmKullanicilarSaga),
        fork(AsuKullanicilarSaga),
        fork(AcenteSaga),
        fork(PolicelerSaga),
        fork(AcentePolicePrimHedefSaga),
        fork(SatilanPolicelerSaga),
    ]);
}
