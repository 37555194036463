import { takeLatest, call, put } from "redux-saga/effects"
import * as types from "./actionTypes"
import * as api from "helpers/api_helper.js"
import { reverseSortById } from "../../helpers/utils"

function* listSatilanPolicelerSaga(action) {
  try {
    const { page,partajNo } = action.payload
    const dataList = yield call(api.listSatilanPoliceler,page,partajNo)
    yield put({ type: types.LIST_SATILAN_POLICELER_SUCCESS, payload: reverseSortById(dataList) })
  } catch (error) {
    yield put({ type: types.LIST_SATILAN_POLICELER_FAILURE, payload: error.message })
  }
}

export default function* satilanPolicelerSaga() {
  yield takeLatest(types.LIST_SATILAN_POLICELER_REQUEST, listSatilanPolicelerSaga)
}
