import * as types from './actionTypes';
export const listBolgelerRequest = () => ({
  type: types.LIST_BOLGELER_REQUEST,
});

export const addBolgeRequest = (segmentData) => ({
  type: types.ADD_BOLGE_REQUEST,
  payload: segmentData,
});

export const updateBolgeRequest = (segmentData) => ({
  type: types.UPDATE_BOLGE_REQUEST,
  payload: { segmentData },
});

export const deleteBolgeRequest = (id) => ({
  type: types.DELETE_BOLGE_REQUEST,
  payload: id,
});