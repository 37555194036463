export const LIST_BM_KULLANICILAR_REQUEST = 'LIST_BM_KULLANICILAR_REQUEST';
export const LIST_BM_KULLANICILAR_SUCCESS = 'LIST_BM_KULLANICILAR_SUCCESS';
export const LIST_BM_KULLANICILAR_FAILURE = 'LIST_BM_KULLANICILAR_FAILURE';

export const ADD_BM_KULLANICI_REQUEST = 'ADD_BM_KULLANICI_REQUEST';
export const ADD_BM_KULLANICI_SUCCESS = 'ADD_BM_KULLANICI_SUCCESS';
export const ADD_BM_KULLANICI_FAILURE = 'ADD_BM_KULLANICI_FAILURE';

export const UPDATE_BM_KULLANICI_REQUEST = 'UPDATE_BM_KULLANICI_REQUEST';
export const UPDATE_BM_KULLANICI_SUCCESS = 'UPDATE_BM_KULLANICI_SUCCESS';
export const UPDATE_BM_KULLANICI_FAILURE = 'UPDATE_BM_KULLANICI_FAILURE';

export const DELETE_BM_KULLANICI_REQUEST = 'DELETE_BM_KULLANICI_REQUEST';
export const DELETE_BM_KULLANICI_SUCCESS = 'DELETE_BM_KULLANICI_SUCCESS';
export const DELETE_BM_KULLANICI_FAILURE = 'DELETE_BM_KULLANICI_FAILURE';
