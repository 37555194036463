export const LIST_POLICELER_REQUEST = 'LIST_POLICELER_REQUEST';
export const LIST_POLICELER_SUCCESS = 'LIST_POLICELER_SUCCESS';
export const LIST_POLICELER_FAILURE = 'LIST_POLICELER_FAILURE';

export const ADD_POLICE_REQUEST = 'ADD_POLICE_REQUEST';
export const ADD_POLICE_SUCCESS = 'ADD_POLICE_SUCCESS';
export const ADD_POLICE_FAILURE = 'ADD_POLICE_FAILURE';

export const UPDATE_POLICE_REQUEST = 'UPDATE_POLICE_REQUEST';
export const UPDATE_POLICE_SUCCESS = 'UPDATE_POLICE_SUCCESS';
export const UPDATE_POLICE_FAILURE = 'UPDATE_POLICE_FAILURE';

export const DELETE_POLICE_REQUEST = 'DELETE_POLICE_REQUEST';
export const DELETE_POLICE_SUCCESS = 'DELETE_POLICE_SUCCESS';
export const DELETE_POLICE_FAILURE = 'DELETE_POLICE_FAILURE';
