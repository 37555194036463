import * as types from './actionTypes';
export const listBmKullanicilarRequest = () => ({
  type: types.LIST_BM_KULLANICILAR_REQUEST,
});

export const addBmKullaniciRequest = (addData) => ({
  type: types.ADD_BM_KULLANICI_REQUEST,
  payload: addData,
});

export const updateBmKullaniciRequest = (updateData) => ({
  type: types.UPDATE_BM_KULLANICI_REQUEST,
  payload: { updateData },
});

export const deleteBmKullaniciRequest = (useruniqid) => ({
  type: types.DELETE_BM_KULLANICI_REQUEST,
  payload: useruniqid,
});