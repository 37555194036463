import { PROFILE_ERROR, PROFILE_SUCCESS, CHANGE_PASSWORD, RESET_PROFILE_FLAG } from "./actionTypes"

export const changePassword = (newPassword) => {
  return {
    type: CHANGE_PASSWORD,
    payload: { newPassword  },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}
