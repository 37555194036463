import * as types from './actionTypes';
export const listAsuKullanicilarRequest = () => ({
  type: types.LIST_ASU_KULLANICILAR_REQUEST,
});

export const addAsuKullaniciRequest = (addData) => ({
  type: types.ADD_ASU_KULLANICI_REQUEST,
  payload: addData,
});

export const updateAsuKullaniciRequest = (updateData) => ({
  type: types.UPDATE_ASU_KULLANICI_REQUEST,
  payload: { updateData },
});

export const deleteAsuKullaniciRequest = (useruniqid) => ({
  type: types.DELETE_ASU_KULLANICI_REQUEST,
  payload: useruniqid,
});