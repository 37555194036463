import { takeLatest, call, put } from "redux-saga/effects"
import * as types from "./actionTypes"
import * as api from "helpers/api_helper.js"
import { parseRules, policeOptions, rulesGenerateDescription } from "../../helpers/utils"
import { listPolicePrimLoading, listPolicePrimSuccess, listPolicePrimFailure } from "./actions"

function* listAcentePolicePrimSaga(action) {
  try {
    const { partajNo } = action.payload
    yield put(listPolicePrimLoading(partajNo,true)); // İsteğin başladığını belirtmek için loading aksiyonunu gönder

    const data = yield call(api.listAcentePolicePrim, partajNo)
    const parsedRules = [parseRules(data.kural)]

    const groupedData = data.policeler.reduce((acc, item) => {
      // Check if partition_type_code_desc is null, if so, skip the iteration
      if (item.partition_type_code_desc === null) {
        return acc;
      }

      console.log("1", item.partition_type_code_desc);

      if (!acc[item.partition_type_code_desc]) {
        acc[item.partition_type_code_desc] = {
          name: item.partition_type_code_desc,
          prim: 0,
          hedefPrim: 0,
          primOran: 0,
          adet: 0,
          hedefAdet: 0,
          adetOran: 0
        };
      }

      acc[item.partition_type_code_desc].prim += parseInt(item.prim);
      acc[item.partition_type_code_desc].adet++;

      return acc;
    }, {});

    for (let rule of parsedRules) {
      for (let key in rule) {
        if (rule.hasOwnProperty(key)) {
          for (let item of rule[key]) {
            const police = (policeOptions.find(p => p.value === item.police) || {}).partition_type_code_desc || null
            if (police && Object.keys(groupedData).includes(police)) {
              if (groupedData[police]) {
                groupedData[police].code = item.police
                groupedData[police].hedefPrim += parseInt(item.tutar)
                groupedData[police].hedefAdet += parseInt(item.adet)
                groupedData[police].primOran = (groupedData[police].hedefPrim > 0 && groupedData[police].prim > 0) ? parseInt(((groupedData[police].prim / groupedData[police].hedefPrim) * 100)).toFixed(0) : 0
                groupedData[police].adetOran = (groupedData[police].hedefAdet > 0 && groupedData[police].adet > 0) ? parseInt(((groupedData[police].adet / groupedData[police].hedefAdet) * 100)).toFixed(0) : 0
              }
            }
          }
        }
      }
    }

    const groups = Object.values(groupedData)

    yield put(listPolicePrimSuccess(partajNo, {
      "police_list": groups,
      "segments": {
        "segment_name": data.segment_name || "",
        "segment_rule": data.kural,
        "segment_description": rulesGenerateDescription(data.kural),
        "parsed_rule": parsedRules[0]
      }
    }));
  } catch (error) {
    const { partajNo } = action.payload
    console.log(error)
    yield put(listPolicePrimFailure(partajNo, error.message));
  }
}

export default function* acentePolicePrimSaga() {
  yield takeLatest(types.LIST_POLICE_PRIM_REQUEST, listAcentePolicePrimSaga)
}
