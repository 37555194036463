import axios from "axios"
import * as url from "./url_helper"
import { getUserUniqId } from "./utils"


const API_URL = "https://allianzsun.zuzzuu.com";

const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

axiosApi.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `${token}`;
      config.headers.authorization = `${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

const getToken = () => {
  const obj = JSON.parse(sessionStorage.getItem("authUser"));
  return obj?.token || null;
};


export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data)

}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data)
}

export const postChangePassword = async (data, config) => {
  try {
    return await post(url.POST_CHANGE_PASSWORD, data, config);
  } catch (error) {
    throw error;
  }
};

export const postLogin = async (data) => {
  try {
    return await post(url.POST_LOGIN, data);
  } catch (error) {
    let message;
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Kullanıcı adı veya şifre yanlış";
          break;
        default:
          message = error[1];
          break;
      }
    }
    throw new Error(message);
  }
};

export const listSegments = async () => {
  return await post(`${API_URL}:19001/segments`, {
    "token": getToken()
  })
}

export const addSegment = async (segmentData) => {
  return await post(`${API_URL}:19001/segmentsadd`, {
    ...segmentData,
    "token": getToken()
  })
}

export const updateSegment = async (id, segmentData) => {
  return await post(`${API_URL}:19001/segmentsupdate/${id}`, {
    ...segmentData,
    "token": getToken()
  })
}

export const deleteSegment = async (id) => {
  return await post(`${API_URL}:19001/segmentsdelete/${id}/delete`, {
    "token": getToken()
  })
}


export const listBolgeler = async () => {
  return await post(`${API_URL}:19999/bolgelerlist`, {
    "token": getToken()
  })
}

export const addBolge = async (addData) => {
  return await post(`${API_URL}:19999/bolgeleradd`, {
    ...addData,
    "token": getToken()
  })
}

export const updateBolge = async (id, updateData) => {
  return await post(`${API_URL}:19999/bolgelerupdate/${id}`, {
    ...updateData,
    "token": getToken()
  })
}

export const deleteBolge = async (id) => {
  return await post(`${API_URL}:19999/bolgelerdelete/${id}`, {
    "token": getToken()
  })
}


export const listBmKullanicilar = async () => {
  return await post(`${API_URL}:19000/userlistbm`, {
    "token": getToken()
  })
}

export const addBmKullanicilar = async (addData) => {
  return await post(`${API_URL}:19000/adduser`, {
    ...addData,
    "token": getToken()
  })
}

export const updateBmKullanicilar = async (id, updateData) => {
  return await post(`${API_URL}:19000/updateuser/${id}`, {
    ...updateData,
    "token": getToken()
  })
}

export const deleteBmKullanicilar = async (id) => {
  return await post(`${API_URL}:19000/deleteuser/${id}`, {
    "token": getToken()
  })
}

export const listAsuKullanicilar = async () => {
  return await post(`${API_URL}:19000/userlistasu`, {
    "token": getToken()
  })
}

export const addAsuKullanicilar = async (addData) => {
  return await post(`${API_URL}:19000/adduser`, {
    ...addData,
    "token": getToken()
  })
}

export const updateAsuKullanicilar = async (id, updateData) => {
  return await post(`${API_URL}:19000/updateuser/${id}`, {
    ...updateData,
    "token": getToken()
  })
}

export const deleteAsuKullanicilar = async (id) => {
  return await post(`${API_URL}:19000/deleteuser/${id}`, {
    "token": getToken()
  })
}

export const listAcenteler = async () => {
  return await post(`${API_URL}:18888/acentelerlist`, {
    "token": getToken()
  })
}
export const listUserAcenteler = async (partajNo) => {
  let requestBody
  if (!partajNo)
    requestBody = {
      "token": getToken(),
      "useruniqid": getUserUniqId()
    }
  else {
    requestBody = {
      "token": getToken(),
      "useruniqid": getUserUniqId(),
      "partaj_no": partajNo
    }
  }
  return await post(`${API_URL}:18888/getAcenteler`, requestBody)
}

export const addAcente = async (segmentData) => {
  return await post(`${API_URL}:18888/acenteleradd`, {
    ...segmentData,
    "token": getToken()
  })
}

export const updateAcente = async (id, segmentData) => {
  return await put(`${API_URL}:18888/acentelerupdate/${id}`, {
    ...segmentData,
    "token": getToken()
  })
}

export const deleteAcente = async (id) => {
  return await post(`${API_URL}:18888/acentelerdelete/${id}`, {
    "token": getToken()
  })
}

export const listSatilanPoliceler = async (page, partajNo) => {
  const url = partajNo ? `${API_URL}:19004/getrecordsbyagency` : `${API_URL}:19004/getallrecords`
  const payload = partajNo ? {
    "page": page,
    "partajNo": partajNo
  } : {
    "page": page
  }
  return await post(url, payload)
}
export const listAcentePolicePrim = async (partajNo) => {
  const url = `${API_URL}:19005/isleVeDon`
  const payload = {
    "partajNo": partajNo
  } ;
  return await post(url, payload)
}
export const listPoliceler = async () => {
  return await post(`${API_URL}:9002/policetipleri`, {
    "token": getToken()
  })
}

export const addPolice = async (segmentData) => {
  return await post(`${API_URL}:9002/policetipleri/add`, {
    ...segmentData,
    "token": getToken()
  })
}

export const updatePolice = async (id, segmentData) => {
  return await put(`${API_URL}:9002/policetipleri/update/${id}`, {
    ...segmentData,
    "token": getToken()
  })
}

export const deletePolice = async (id) => {
  return await post(`${API_URL}:9002/policetipleri/delete/${id}`, {
    "token": getToken()
  })
}