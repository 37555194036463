import * as types from './actionTypes';

export const listPolicePrimRequest = (partajNo) => ({
  type: types.LIST_POLICE_PRIM_REQUEST,
  payload:  partajNo
})
export const listPolicePrimLoading = (partajNo,status) => ({
  type: types.LIST_POLICE_PRIM_LOADING,
  payload: { partajNo,status },
});

export const listPolicePrimSuccess = (partajNo, data) => ({
  type: types.LIST_POLICE_PRIM_SUCCESS,
  payload: { partajNo, ...data },
});

export const listPolicePrimFailure = (partajNo, errorMessage) => ({
  type: types.LIST_POLICE_PRIM_FAILURE,
  payload: { partajNo, errorMessage },
});