import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import withRouter from "components/Common/withRouter"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

// actions
import { changePassword } from "../../store/actions"
import { t } from "i18next"

const UserProfile = () => {

  //meta title
  document.title = "Profil | ZUZZUU "

  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState("")
  const [nameSurname, setNameSurname] = useState("")

  const ProfileProperties = createSelector(
    (state) => state.Profile,
    (profile) => ({
      error: profile.error,
      success: profile.success
    })
  )

  const {
    error,
    success
  } = useSelector(ProfileProperties)

  const obj = JSON.parse(sessionStorage.getItem("authUser"))
  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      setNameSurname(obj.name + " " + obj.surname)
      setName(obj.username)
    }

  }, [dispatch, success])

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      newPassword: ""
    },
    validationSchema: Yup.object({
      newPassword: Yup.string().required("Lütfen yeni şifrenizi giriniz")
    }),
    onSubmit: (values) => {
      dispatch(changePassword(values.newPassword))
    }
  })

  const [showPassword, setShowPassword] = useState(false) // Şifreyi göster/gizle durumu

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={t("Profile")} breadcrumbItem={t("Profile")} />

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">Şifreniz başarıyla güncellendi</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <div
                        className="d-flex avatar-md rounded-circle img-thumbnail justify-content-center align-items-center"
                      ><i className="fa fa-user font-size-24 "></i></div>
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5 className="ms-2">{name}</h5>
                        <p className="mb-1 ms-2">{nameSurname}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">{t("ChangePassword")}</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal w-50"
                onSubmit={(e) => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="form-group">
                  <Label className="form-label">{t("NewPassword")}</Label>
                  <div className="position-relative">
                    <Input
                      name="newPassword"
                      className="form-control"
                      autoComplete="off"
                      placeholder="Yeni şifreniz"
                      type={showPassword ? "text" : "password"} // Şifreyi göster/gizle durumuna göre değiştir
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.newPassword || ""}
                      invalid={
                        !!(validation.touched.newPassword && validation.errors.newPassword)
                      }
                    />
                    <div
                      className="position-absolute top-50 end-0 translate-middle-y me-4"
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <i className="fa fa-eye" size={20} /> :
                        <i className="fa fa-eye-slash" size={20} />}
                    </div>
                  </div>
                  {validation.touched.newPassword && validation.errors.newPassword ? (
                    <FormFeedback type="invalid">{validation.errors.newPassword}</FormFeedback>
                  ) : null}
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="primary">
                    {t("Update")}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
