import { jwtDecode } from "jwt-decode"

export function LogoutOnNonAdmin() {

  if (sessionStorage.getItem("authUser")) {
    const obj = JSON.parse(sessionStorage.getItem("authUser"))
    const username = jwtDecode(obj.token).username
    if (username === "admin") {
      return true
    }
    sessionStorage.removeItem("authUser")
  }
  return false
}

export function checkAuthUser() {
  if (sessionStorage.getItem("authUser")) {
    const obj = JSON.parse(sessionStorage.getItem("authUser"))
    const token = jwtDecode(obj.token)
    if (token) {
      return true
    }
    sessionStorage.removeItem("authUser")
    return false
  }
  return false
}

