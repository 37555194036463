import * as types from './actionTypes';

const initialState = {
  asuKullanicilar: [],
  loading: false,
  error: null,
};

const asuKullanicilar = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_ASU_KULLANICILAR_REQUEST:
    case types.ADD_ASU_KULLANICI_REQUEST:
    case types.UPDATE_ASU_KULLANICI_REQUEST:
    case types.DELETE_ASU_KULLANICI_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.LIST_ASU_KULLANICILAR_SUCCESS:
      return {
        ...state,
        asuKullanicilar: action.payload,
        loading: false,
      };
    case types.ADD_ASU_KULLANICI_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.UPDATE_ASU_KULLANICI_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.DELETE_ASU_KULLANICI_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.LIST_ASU_KULLANICILAR_FAILURE:
    case types.ADD_ASU_KULLANICI_FAILURE:
    case types.UPDATE_ASU_KULLANICI_FAILURE:
    case types.DELETE_ASU_KULLANICI_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export default asuKullanicilar;
