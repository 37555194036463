import React from "react"
import {Navigate} from "react-router-dom"

// Profile
//import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"


import UserProfile from "../pages/Authentication/user-profile"
import Dashboard from "../pages/Dashboard";
import SatilanPoliceler from "../pages/SatilanPoliceler"

const authProtectedRoutes = [
    {path: "/", component: <Dashboard/>},
    // //profile
    {path: "/profile", component: <UserProfile/>},

    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    {
        path: "/",
        exact: true,
        component: <Navigate to="/"/>
    },
    {path: "/satilan-policeler", component: <SatilanPoliceler/>}
]

const publicRoutes = [
    {path: "/login", component: <Login/>},
    {path: "/logout", component: <Logout/>},
]

export {authProtectedRoutes, publicRoutes}
