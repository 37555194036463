import { takeLatest, call, put } from "redux-saga/effects"
import * as types from "./actionTypes"
import * as api from "helpers/api_helper.js"
import { reverseSortById } from "../../helpers/utils"

function* listBmKullanicilarSaga() {
  try {
    const kullanicilar = yield call(api.listBmKullanicilar)
    yield put({ type: types.LIST_BM_KULLANICILAR_SUCCESS, payload: reverseSortById(kullanicilar) })
  } catch (error) {
    yield put({ type: types.LIST_BM_KULLANICILAR_FAILURE, payload: error.message })
  }
}

function* addBmKullaniciSaga(action) {
  try {
    yield call(api.addBmKullanicilar, action.payload)
    yield call(listBmKullanicilarSaga) // Refresh segments after addition
  } catch (error) {
    yield put({ type: types.ADD_BM_KULLANICI_FAILURE, payload: error.message })
  }
}

function* updateBmKullaniciSaga(action) {
  try {
    const { updateData } = action.payload
    yield call(api.updateBmKullanicilar, updateData.useruniqid, updateData)
    yield call(listBmKullanicilarSaga) // Refresh segments after update
  } catch (error) {
    yield put({ type: types.UPDATE_BM_KULLANICI_FAILURE, payload: error.message })
  }
}

function* deleteBmKullaniciSaga(action) {
  try {
    const useruniqid = action.payload
    yield call(api.deleteBmKullanicilar, useruniqid)
    yield call(listBmKullanicilarSaga) // Refresh segments after deletion
  } catch (error) {
    yield put({ type: types.DELETE_BM_KULLANICI_FAILURE, payload: error.message })
  }
}

export default function* bmKullanicilarSaga() {
  yield takeLatest(types.LIST_BM_KULLANICILAR_REQUEST, listBmKullanicilarSaga)
  yield takeLatest(types.ADD_BM_KULLANICI_REQUEST, addBmKullaniciSaga)
  yield takeLatest(types.UPDATE_BM_KULLANICI_REQUEST, updateBmKullaniciSaga)
  yield takeLatest(types.DELETE_BM_KULLANICI_REQUEST, deleteBmKullaniciSaga)
}
