import { call, put, takeEvery } from "redux-saga/effects"
import { jwtDecode } from "jwt-decode"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import * as api from "helpers/api_helper.js"


function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(api.postLogin, {
      kullanici_adi: user.username,
      sifre: user.password
    })

    if (response.token) {
      const decodedToken = jwtDecode(response.token)
      const authUser = {
        ...decodedToken,
        token: response.token
      }
      sessionStorage.setItem("authUser", JSON.stringify(authUser))
      yield put(loginSuccess(response))
      setTimeout(()=>{
        history("/")
      },200)
    }
  } catch (error) {
    yield put(apiError(error?.message))
  }
}


function* logoutUser({ payload: { history } }) {
  try {
    sessionStorage.removeItem("authUser")
    yield put(logoutUserSuccess())
    history("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}


function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
