export const LIST_SEGMENTS_REQUEST = 'LIST_SEGMENTS_REQUEST';
export const LIST_SEGMENTS_SUCCESS = 'LIST_SEGMENTS_SUCCESS';
export const LIST_SEGMENTS_FAILURE = 'LIST_SEGMENTS_FAILURE';

export const ADD_SEGMENT_REQUEST = 'ADD_SEGMENT_REQUEST';
export const ADD_SEGMENT_SUCCESS = 'ADD_SEGMENT_SUCCESS';
export const ADD_SEGMENT_FAILURE = 'ADD_SEGMENT_FAILURE';

export const UPDATE_SEGMENT_REQUEST = 'UPDATE_SEGMENT_REQUEST';
export const UPDATE_SEGMENT_SUCCESS = 'UPDATE_SEGMENT_SUCCESS';
export const UPDATE_SEGMENT_FAILURE = 'UPDATE_SEGMENT_FAILURE';

export const DELETE_SEGMENT_REQUEST = 'DELETE_SEGMENT_REQUEST';
export const DELETE_SEGMENT_SUCCESS = 'DELETE_SEGMENT_SUCCESS';
export const DELETE_SEGMENT_FAILURE = 'DELETE_SEGMENT_FAILURE';
export const GET_SEGMENT_DETAIL_REQUEST = 'GET_SEGMENT_DETAIL_REQUEST';
export const GET_SEGMENT_DETAIL_SUCCESS = 'GET_SEGMENT_DETAIL_SUCCESS';
export const GET_SEGMENT_DETAIL_FAILURE = 'GET_SEGMENT_DETAIL_FAILURE';