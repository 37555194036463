import policeKuralBaseObje from "../assets/data/policeKuralBaseObje.json"

export const reverseSortById = (list) => {
  return list.slice().sort((a, b) => b.id - a.id)
}

export const getUserRole = () => {
  if (sessionStorage.getItem("authUser")) {
    const obj = JSON.parse(sessionStorage.getItem("authUser"))
    return obj?.rol || null;
  }
  return null
}
export const getUserAgencyPartajNo = () => {
  if (sessionStorage.getItem("authUser")) {
    const obj = JSON.parse(sessionStorage.getItem("authUser"))
    return obj?.acente_partajno || null;
  }
  return null
}
export const getUserUniqId = () => {
  if (sessionStorage.getItem("authUser")) {
    const obj = JSON.parse(sessionStorage.getItem("authUser"))
    return obj?.uniquserid || null;
  }
  return null
}
export const rulesGenerateDescription = (ruleString) => {
  if (ruleString) {
    const parsedRule = parseRules(ruleString)
    const mRules = parsedRule.M
      .filter((data) => data.police.trim() !== "") // Boş poliçe girişlerini filtrele
      .map((data, index, array) => {
        const police = policeOptions.find(p => p.value === data.police)?.label || ""
        if (index === array.length - 1) {
          return `${data.adet} adet ${police} ${data.tutar > 0 ? (data.operator === "&&" ? "ve " : "veya ") + data.tutar + `TL tutarında ${police} ` : ""} satılmış olmalıdır`
        } else {
          return `${data.adet} adet ${police} ${data.tutar > 0 ? (data.operator === "&&" ? "ve " : "veya ") + data.tutar + `TL tutarında ${police} ` : ""} satılmış olmalıdır ${data.araOperator === "&&" ? "ve " : "veya "}`
        }
      })
      .join("")

    const pRules = parsedRule.P
      .filter((data) => data.police.trim() !== "")
      .map((data) => {
        const police = policeOptions.find(p => p.value === data.police)?.label || ""
        return ` ${police} 'dan en az ${data.adet} adet  ${data.tutar > 0 ? (data.operator === "&&" ? "ve " : "veya ") + "satış tutarı olarak " + data.tutar + " TL " : ""} satış yapıldığında  bu tutarın % ${data.prim} kadarı prim hakedişi olacaktır. `
      })
      .join(".")
    return `Bu segmentde prim alabilmek için öncelikli koşul: ${mRules} bu işlemlerden prim kazanılmayacaktır. ${pRules}`
  }
  return ``
}

export const getMRuleDescription=(ruleString)=>{
  if (ruleString) {
    const parsedRule = parseRules(ruleString)
    const mRules = parsedRule.M
      .filter((data) => data.police.trim() !== "") // Boş poliçe girişlerini filtrele
      .map((data, index, array) => {
        const police = policeOptions.find(p => p.value === data.police)?.label || ""
        if (index === array.length - 1) {
          return `${data.adet} adet ${police} ${data.tutar > 0 ? (data.operator === "&&" ? "ve " : "veya ") + data.tutar + `TL tutarında ${police} ` : ""} satılmış olmalıdır`
        } else {
          return `${data.adet} adet ${police} ${data.tutar > 0 ? (data.operator === "&&" ? "ve " : "veya ") + data.tutar + `TL tutarında ${police} ` : ""} satılmış olmalıdır ${data.araOperator === "&&" ? "ve " : "veya "}`
        }
      })
      .join("")

    return `Bu segmentde prim alabilmek için öncelikli koşul: ${mRules} bu işlemlerden prim kazanılmayacaktır.`
  }
  return ``
}

export const getSinglePRuleDescription = ({ police = "", adet = 0, tutar = 0, operator = "&&", prim = 0 }) =>
  `${police} 'dan en az ${adet} adet${tutar > 0 ? ` ${operator === "&&" ? "ve" : "veya"} satış tutarı olarak ${tutar} TL` : ""} yapıldığında bu tutarın %${prim} kadarı prim hakedişi olacaktır.`;

export const parseRules = (ruleString) => {
  const parts = ruleString.split(";")
  const MRules = []
  const PRules = []

  parts.forEach((part) => {
    const [type, rules] = part.split(":")
    if (type === "M") {
      MRules.push(...parsedMRule(rules))
    } else if (type === "P") {
      const segments = rules ? rules.split(";") : []
      segments.forEach((segment) => {
        const obje = { ...policeKuralBaseObje }
        const policeMatch = segment.match(/^(.*?)_SR=/)
        obje.police = policeMatch ? policeMatch[1] : null
        const operatorMatch = segment.match(new RegExp(`(.{2})${obje.police}_PC`))
        obje.operator = operatorMatch ? operatorMatch[1] : null
        const adetMatch = segment.match(/_PC=(.*?),/)
        const tutarMatch = segment.match(new RegExp(`_SR=(.*?)${obje.operator.replace(/([\\^$*+?.()|{}[\]])/g, "\\$1")}`))
        const primMatch = segment.match(/B=%(.*)$/)

        obje.adet = adetMatch ? parseInt(adetMatch[1]) : 0
        obje.tutar = tutarMatch ? parseInt(tutarMatch[1]) : 0
        obje.prim = primMatch ? parseInt(primMatch[1]) : 0
        PRules.push(obje)
      })
    }
  })

  return { M: MRules, P: PRules }
}
const parsedMRule = (MRuleString) => {
  const MRules = []
  const mRulesSplitting = MRuleString.split("SR").map((rule, index, array) => {
    return rule
  })
  mRulesSplitting.filter(rule => rule !== "").map((line, index) => {
    const obje = { ...policeKuralBaseObje }
    if (isFirstTwoOperators(mRulesSplitting[index + 1])) {
      obje.araOperator = mRulesSplitting[index + 1].substring(0, 2)
    }
    const indexOfN = line.indexOf("_N=")
    if (indexOfN !== -1) {
      if (isFirstTwoOperators(line)) {
        obje.police = line.substring(2, indexOfN)
      } else {
        obje.police = line.substring(0, indexOfN)
      }
    }
    const adetMatch = line.match(/_N=(.*?)_PC/)
    const tutarMatch = line.match(/_RN=(.*?)_/)
    const operatorMatch = line.match(/_PC(.{2})/)
    obje.operator = operatorMatch ? operatorMatch[1] : "&&"
    obje.adet = adetMatch ? parseInt(adetMatch[1]) : 0
    obje.tutar = tutarMatch ? parseInt(tutarMatch[1]) : 0
    MRules.push(obje)
  })
  return MRules
}

export function isFirstTwoOperators(str) {
  return str?.startsWith("&&") || str?.startsWith("||")
}

export const getTextColor = (target,real)=>{
  return target === 0 && real === 0  ? "text-muted" :  target<=real ? "text-success" : "text-danger";
}
export const checkKazanim = (hedefTutar, hedefAdet, gerceklesenAdet,gerceklesenTutar, operator) => {
  return operator === undefined ? false : operator === "&&" ? (hedefTutar <= gerceklesenTutar) && (hedefAdet <= gerceklesenAdet) : (hedefTutar <= gerceklesenTutar) || (hedefAdet <= gerceklesenAdet);
}
export const policeOptions = [
  { value: "TSS", label: "Tamamlayıcı Sağlık Sigortası (TSS)", partition_type_code_desc: "ALLIANZ TAMAMLAYICI SAĞLIK" },
  { value: "MSS", label: "Modüler Sağlık Sigortası (MSS)", partition_type_code_desc: "ALLIANZ MODÜLER SAĞLIK" },
  { value: "SFK", label: "Sağlık Ferdi Kaza", partition_type_code_desc: "SAĞLIK FERDİ KAZA" },
  { value: "GS", label: "Grup Sağlık ", partition_type_code_desc: "GRUP SAĞLIK" },
  { value: "KONUT", label: "Yuvam", partition_type_code_desc: "YUVAM" },
  { value: "DASK", label: "DASK", partition_type_code_desc: "DASK" },
  { value: "TRAFİK", label: "Trafik", partition_type_code_desc: "TRAFİK" },
  { value: "İşyeri", label: "İşyeri Sigortası", partition_type_code_desc: "İşyeri" },
  { value: "KASKO", label: "Kasko Sigortası", partition_type_code_desc: "KASKO" },
  {
    value: "KMAZMSST",
    label: "KARAYOLU MOTORLU ARAÇLAR ZORUNLU MALİ SORUMLULUK SİGORTASI TRAFİK",
    partition_type_code_desc: "KARAYOLU MOTORLU ARAÇLAR ZORUNLU MALİ SORUMLULUK SİGORTASI TRAFİK"
  },
  {
    value: "TMVTAZMS",
    label: "TEHLİKELİ MADDELER VE TEHLİKELİ ATIK ZORUNLU MALİ SORUMLULUK",
    partition_type_code_desc: "TEHLİKELİ MADDELER VE TEHLİKELİ ATIK ZORUNLU MALİ SORUMLULUK"
  }
]