import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { CHANGE_PASSWORD } from "./actionTypes"
import { profileSuccess, profileError } from "./actions"
import * as api from "helpers/api_helper.js"


function* changePassword({ payload: { newPassword } }) {
  try {
    if (sessionStorage.getItem("authUser")) {

      const obj = JSON.parse(sessionStorage.getItem("authUser"))

      const response = yield call(api.postChangePassword, {
        "newPassword": newPassword,
        "token": obj.token
      })
      yield put(profileSuccess(response))
    }
  } catch (error) {
    yield put(profileError(error.message))
  }
}
export function* watchProfile() {
  yield takeEvery(CHANGE_PASSWORD, changePassword)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
