import { takeLatest, call, put } from "redux-saga/effects"
import * as types from "./actionTypes"
import * as api from "helpers/api_helper.js"
import { reverseSortById } from "../../helpers/utils"

function* listAsuKullanicilarSaga() {
  try {
    const kullanicilar = yield call(api.listAsuKullanicilar)
    yield put({ type: types.LIST_ASU_KULLANICILAR_SUCCESS, payload: reverseSortById(kullanicilar) })
  } catch (error) {
    yield put({ type: types.LIST_ASU_KULLANICILAR_FAILURE, payload: error.message })
  }
}

function* addAsuKullaniciSaga(action) {
  try {
    yield call(api.addAsuKullanicilar, action.payload)
    yield call(listAsuKullanicilarSaga) // Refresh segments after addition
  } catch (error) {
    yield put({ type: types.ADD_ASU_KULLANICI_FAILURE, payload: error.message })
  }
}

function* updateAsuKullaniciSaga(action) {
  try {
    const { updateData } = action.payload
    yield call(api.updateAsuKullanicilar, updateData.useruniqid, updateData)
    yield call(listAsuKullanicilarSaga) // Refresh segments after update
  } catch (error) {
    yield put({ type: types.UPDATE_ASU_KULLANICI_FAILURE, payload: error.message })
  }
}

function* deleteAsuKullaniciSaga(action) {
  try {
    const useruniqid = action.payload
    yield call(api.deleteAsuKullanicilar, useruniqid)
    yield call(listAsuKullanicilarSaga) // Refresh segments after deletion
  } catch (error) {
    yield put({ type: types.DELETE_ASU_KULLANICI_FAILURE, payload: error.message })
  }
}

export default function* asuKullanicilarSaga() {
  yield takeLatest(types.LIST_ASU_KULLANICILAR_REQUEST, listAsuKullanicilarSaga)
  yield takeLatest(types.ADD_ASU_KULLANICI_REQUEST, addAsuKullaniciSaga)
  yield takeLatest(types.UPDATE_ASU_KULLANICI_REQUEST, updateAsuKullaniciSaga)
  yield takeLatest(types.DELETE_ASU_KULLANICI_REQUEST, deleteAsuKullaniciSaga)
}
