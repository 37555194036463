import * as types from './actionTypes';

const initialState = {
  bolgeler: [],
  loading: false,
  error: null,
};

const bolgeler = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_BOLGELER_REQUEST:
    case types.ADD_BOLGE_REQUEST:
    case types.UPDATE_BOLGE_REQUEST:
    case types.DELETE_BOLGE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.LIST_BOLGELER_SUCCESS:
      return {
        ...state,
        bolgeler: action.payload,
        loading: false,
      };
    case types.ADD_BOLGE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.UPDATE_BOLGE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.DELETE_BOLGE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.LIST_BOLGELER_FAILURE:
    case types.ADD_BOLGE_FAILURE:
    case types.UPDATE_BOLGE_FAILURE:
    case types.DELETE_BOLGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export default bolgeler;
