import React, { Fragment, useEffect, useMemo, useState } from "react"
import { Row, Table, Button, Col } from "reactstrap"
import { Link } from "react-router-dom"

import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender
} from "@tanstack/react-table"

import { rankItem } from "@tanstack/match-sorter-utils"
import JobListGlobalFilter from "./GlobalSearchFilter"
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const Filter = ({ column }) => {
  const columnFilterValue = column.getFilterValue()

  return (
    <>
      <DebouncedInput
        type="text"
        value={columnFilterValue ?? ""}
        onChange={(value) => column.setFilterValue(value)}
        placeholder="Search..."
        className="w-36 border shadow rounded"
        list={column.id + "list"}
      />
      <div className="h-1" />
    </>
  )
}

const DebouncedInput = ({
                          value: initialValue,
                          onChange,
                          debounce = 500,
                          ...props
                        }) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [debounce, onChange, value])

  return (
    <React.Fragment>
      <Col sm={4}>
        <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />
      </Col>
    </React.Fragment>
  )
}


const SSTableContainer = ({
                            childComponent = null,
                            showRecordSizes = [100, 200, 500],
                            dataLength = 0,
                            columns,
                            selectedPage,
                            data,
                            tableClass,
                            theadClass,
                            divClassName,
                            isBordered,
                            isPagination,
                            isGlobalFilter,
                            paginationWrapper,
                            SearchPlaceholder,
                            pagination,
                            buttonClass,
                            buttonName,
                            isAddButton,
                            isCustomPageSize,
                            handleUserClick,
                            isJobListGlobalFilter,
                            onPageChange
                          }) => {
  const [columnFilters, setColumnFilters] = useState([])
  const [globalFilter, setGlobalFilter] = useState("")
  const [currentPage, setCurrentPage] = useState(selectedPage)

  const fuzzyFilter = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value)
    addMeta({
      itemRank
    })
    return itemRank.passed
  }

  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter
    },
    state: {
      columnFilters,
      globalFilter
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel()
  })

  const {
    getHeaderGroups,
    getRowModel,
    setPageIndex // Yeni: Sayfa numarasını değiştirmek için
  } = table

  useEffect(() => {
    onPageChange(currentPage)
  }, [currentPage])

  const dataSize = dataLength?.totalnumbercount || data?.length || 0
  const pageSize = showRecordSizes[0]

  useEffect(() => {
    table.setPageSize(pageSize)
  }, [table, pageSize])


  const totalPages = Math.ceil(dataSize / pageSize)

  const paginationItems = [
    <li
      key="previous"
      className={`paginate_button page-item previous ${
        currentPage <= 0 ? "disabled" : ""
      }`}
    >
      <Link to="#" className="page-link" onClick={() => {
        const prevPage = currentPage - 1 > 0 ? currentPage - 1 : 0
        setPageIndex(prevPage)
        setCurrentPage(prevPage)
      }}>
        <i className="mdi mdi-chevron-left"></i>
      </Link>
    </li>,
    Array.from({ length: totalPages }).map((_, index) => {
      const item = index
      return (
        (currentPage === item || currentPage === item + 1 || currentPage === item - 1) && (
          <li
            key={index}
            className={`paginate_button page-item ${
              currentPage === item ? "active" : ""
            }`}
          >
            <Link
              to="#"
              className="page-link"
              onClick={() => {
                setPageIndex(item)
                setCurrentPage(item)
              }}
            >
              {item + 1}
            </Link>
          </li>
        )
      )
    }),
    <li
      key="next"
      className={`paginate_button page-item next ${
        !currentPage >= totalPages ? "disabled" : ""
      }`}
    >
      <Link to="#" className="page-link" onClick={() => {
        const nextPage = currentPage + 1 < totalPages ? currentPage + 1 : totalPages - 1
        setPageIndex(nextPage)
        setCurrentPage(nextPage)
      }}>
        <i className="mdi mdi-chevron-right"></i>
      </Link>
    </li>
  ]


  return (
    <Fragment>
      <Row className="mb-2">
        {childComponent &&
          childComponent
        }
        {isCustomPageSize && (
          <Col sm={2}>
            <select
              className="form-select pageSize mb-2"
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value))
              }}
            >
              {showRecordSizes.map((size) => (
                <option key={size} value={size}>
                  {size} Kayıt Göster
                </option>
              ))}
            </select>
          </Col>
        )}

        {isGlobalFilter && (
          <DebouncedInput
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
            className="form-control search-box me-2 mb-2 d-inline-block"
            placeholder={SearchPlaceholder}
          />
        )}

        {isJobListGlobalFilter && (
          <JobListGlobalFilter setGlobalFilter={setGlobalFilter} />
        )}

        {isAddButton && (
          <Col sm={6}>
            <div className="text-sm-end">
              <Button
                type="button"
                className={
                  buttonClass ?? "btn btn-soft-primary waves-effect waves-light"
                }
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus me-1"></i> {buttonName}
              </Button>
            </div>
          </Col>
        )}
        <Col sm={2}>
          <ReactHTMLTableToExcel
            id="test-table-xlsx-button"
            className="btn btn-primary"
            table="table-to-xlsx"
            filename="Satılan Poliçeler "
            sheet="tablexlsx"
            buttonText="Excel olarak indir" />
        </Col>
      </Row>


      <div className={divClassName ? divClassName : "table-responsive"}>
        <Table id="table-to-xlsx" hover className={tableClass} bordered={isBordered}>
          <thead className={theadClass}>
          {getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={`${
                      header.column.columnDef.enableSorting
                        ? "sorting sorting_desc"
                        : ""
                    }`}
                  >
                    {header.isPlaceholder ? null : (
                      <React.Fragment>
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer select-none"
                              : "",
                            onClick: header.column.getToggleSortingHandler()
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: "",
                            desc: ""
                          }[header.column.getIsSorted()] ?? null}
                        </div>
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} table={table} />
                          </div>
                        ) : null}
                      </React.Fragment>
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
          </thead>

          <tbody>
          {getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
          </tbody>
        </Table>
      </div>
      {isPagination && (
        <Row>
          <Col sm={12} md={5}>
            <div className="dataTables_info">
              {dataSize} sonuçtan {currentPage * pageSize + 1}-
              {Math.min(dataSize, (currentPage + 1) * pageSize)} arası kayıt
              gösteriliyor
            </div>
          </Col>
          <Col sm={12} md={7}>
            <div className={paginationWrapper}>
              <ul className={pagination}>
                {paginationItems}
              </ul>
            </div>
          </Col>
        </Row>
      )}
    </Fragment>
  )
}

export default SSTableContainer


