import { takeLatest, takeEvery, call, put } from "redux-saga/effects"
import * as types from "./actionTypes"
import * as api from "helpers/api_helper.js"
import { reverseSortById } from "../../helpers/utils"

function* listSegmentsSaga() {
  try {
    const segments = yield call(api.listSegments)
    yield put({ type: types.LIST_SEGMENTS_SUCCESS, payload: reverseSortById(segments) })
  } catch (error) {
    yield put({ type: types.LIST_SEGMENTS_FAILURE, payload: error.message })
  }
}

function* addSegmentSaga(action) {
  try {
    yield call(api.addSegment, action.payload)
    yield call(listSegmentsSaga) // Refresh segments after addition
  } catch (error) {
    yield put({ type: types.ADD_SEGMENT_FAILURE, payload: error.message })
  }
}

function* updateSegmentSaga(action) {
  try {
    const { segmentData } = action.payload
    yield call(api.updateSegment, segmentData.id, segmentData)
    yield call(listSegmentsSaga) // Refresh segments after update
  } catch (error) {
    yield put({ type: types.UPDATE_SEGMENT_FAILURE, payload: error.message })
  }
}

function* deleteSegmentSaga(action) {
  try {
    const id = action.payload
    yield call(api.deleteSegment, id)
    yield call(listSegmentsSaga) // Refresh segments after deletion
  } catch (error) {
    yield put({ type: types.DELETE_SEGMENT_FAILURE, payload: error.message })
  }
}

export default function* segmentSaga() {
  yield takeLatest(types.LIST_SEGMENTS_REQUEST, listSegmentsSaga)
  yield takeLatest(types.ADD_SEGMENT_REQUEST, addSegmentSaga)
  yield takeLatest(types.UPDATE_SEGMENT_REQUEST, updateSegmentSaga)
  yield takeLatest(types.DELETE_SEGMENT_REQUEST, deleteSegmentSaga)
}
