import * as types from './actionTypes';
export const listAcenteLerRequest = () => ({
  type: types.LIST_ACENTELER_REQUEST,
});
export const listUserAcenteLerRequest = (partajNo) => ({
  type: types.LIST_USER_ACENTELER_REQUEST,
  payload:partajNo,
});

export const addAcenteRequest = (segmentData) => ({
  type: types.ADD_ACENTE_REQUEST,
  payload: segmentData,
});

export const updateAcenteRequest = (id, segmentData) => ({
  type: types.UPDATE_ACENTE_REQUEST,
  payload: { id, segmentData },
});

export const deleteAcenteRequest = (id) => ({
  type: types.DELETE_ACENTE_REQUEST,
  payload: id,
});
